import React, { useEffect } from 'react';
import Layout from '../components/dfa-theme/layout';
import ContentBlock from '../components/content-block';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ContactForm from '../components/contact-form';
import { CONSTANTS } from '../utils/constants';
import '../scss/pages/anatomy.scss';

const Anatomy = () => {
  const metaTags = {
    name: 'Deerfield AOB',
    description: 'Deerfield AOB',
    keywords: 'Deerfield, AOB, Agency of Brand',
  };

  // Dynamically load Wistia script after component mounts
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://fast.wistia.net/assets/external/E-v1.js';
    script.async = true;
    
    // Append the script to the body
    document.body.appendChild(script);

    return () => {
      // Clean up the script tag when component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Layout meta={metaTags}>
      <div className='anatomy-hero-container'>
        <ContentBlock
          outerBgColor={CONSTANTS.TAN}
          className='anatomy-header block'
        >
          <Grid>
            <Row>
              <Col xs={1}></Col>
              <Col xs={10} className='anatomy-content title-block'>
                {/* <p className='dissect-text'>Dissecting the</p>
                <h1 className='title-margin'>
                  <span className='large-text'>ANATOMY</span>
                  <span className='small-text' style={{ paddingLeft: '3px', paddingRight: '3px' }}> OF </span>
                  <span className='large-text'>MARKETING</span>
                </h1> */}
              </Col>
              <Col xs={1}></Col>
            </Row>
          </Grid>
        </ContentBlock>
        <ContentBlock outerBgColor={CONSTANTS.TAN} className='block'>
          <Grid>
            <Row>
              {/* <Col lg={1}></Col> */}
              <Col xs={12} className='anatomy-content'>
                
                {/* Wistia Video Embed */}
                <div className='wistia_responsive_padding' style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <div className='wistia_responsive_wrapper' style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%' }}>
                    <iframe 
                      src="https://fast.wistia.net/embed/iframe/ycpf4pha34?seo=true&videoFoam=true" 
                      title="Teva Hope for Schizophrenia Care Video" 
                      allow="autoplay; fullscreen" 
                      allowTransparency="true" 
                      frameBorder="0" 
                      scrolling="no" 
                      className="wistia_embed" 
                      name="wistia_embed" 
                      msallowfullscreen="true" 
                      width="100%" 
                      height="100%" 
                    ></iframe>
                  </div>
                </div>

              </Col>
              {/* <Col lg={1}></Col> */}
            </Row>
          </Grid>
        </ContentBlock>
      </div>
    </Layout>
  );
};
export default Anatomy;